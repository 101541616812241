
'use strict'
import axios from 'axios'
import { api, files } from '../utils/utilidades'
axios.defaults.baseURL = api;
export default {
    namespaced: true,
    state: {
        urlFiles: files,
        tabCabecera: false,
        tabOperaciones: true,
        tabSuboperaciones: true,
        tabSig: true,
        tabMateriales: true,
        tabServicios: true,
        tabTrazabilidad: true,
        tabSolicitudes: true,
        selectTab: 0,

        itemsTipoOrden: [],
        itemsPrioridadOrden: [],
        itemsDisciplinaOrden: [],
        itemsEstadoOrden: [],
        itemsParadaOrden: [],
        itemsConformidadOrden: [],

        listaEquipos: [],
        ubicacionEquipos: [],
        listaCentroCostos: [],
        listaDepartamentos: [],
        listaResponsables: [],

        itemsSuboperaciones: [],
        itemsControlesSig: [],

        itemsTipoSuboperaciones: [],
        itemsCategoriaSuboperaciones: [],
        itemsTipoControlesSig: [],
        itemsCategoriaControlesSig: [],

        listaMateriales: [],
        itemsUMmaterial: [],
        itemsTipoMaterial: [],
        itemsMoneda: [],
        itemsUMservicio: [],
        listaServicios: [],
        listaEmpresas: [],
        listaRecursos: [],

        orden_trabajo: [],
        listaOperaciones: [],
        listaSubOperaciones: [],
        listaControlesSig: [],
        listaMaterialesOrden: [],
        listaServiciosOrden: [],
        editOperacion: false,
        editarOperacion: false,
        editarSuboperacion: false,
        editarControl: false,
        editarMaterial: false,
        editarServicio: false,
        editMaterial: [],
        editServicio: [],
        ordenObtenida: null,
        numeroOrden: null,
        resetearOrden: false,
        listaOrdenes: [],
        listaOrdenesPlanificar: [],
        listaOrdenesEjecutadas: [],
        listaOrdenesEjecucion: [],
        listaOrdenesProgramar: [],
        nuevaOrden: false,
        listaOrdenesFiltradas: [],
        listaTrazabilidad: [],
        listaModoFallo: [],
        listaTiposEquipos: [],
        listaAreas: [],
    },
    getters: {
        urlFiles: state => state.urlFiles,
        tabCabecera: state => state.tabCabecera,
        tabOperaciones: state => state.tabOperaciones,
        tabSuboperaciones: state => state.tabSuboperaciones,
        tabSig: state => state.tabSig,
        tabMateriales: state => state.tabMateriales,
        tabServicios: state => state.tabServicios,
        tabTrazabilidad: state => state.tabTrazabilidad,
        tabSolicitudes: state => state.tabSolicitudes,
        selectTab: state => state.selectTab,

        itemsTipoOrden: state => state.itemsTipoOrden,
        itemsPrioridadOrden: state => state.itemsPrioridadOrden,
        itemsDisciplinaOrden: state => state.itemsDisciplinaOrden,
        itemsEstadoOrden: state => state.itemsEstadoOrden,
        itemsParadaOrden: state => state.itemsParadaOrden,
        itemsConformidadOrden: state => state.itemsConformidadOrden,

        listaEquipos: state => state.listaEquipos,
        ubicacionEquipos: state => state.ubicacionEquipos,
        listaCentroCostos: state => state.listaCentroCostos,
        listaDepartamentos: state => state.listaDepartamentos,
        listaResponsables: state => state.listaResponsables,
        listaRecursos: state => state.listaRecursos,

        itemsSuboperaciones: state => state.itemsSuboperaciones,
        itemsControlesSig: state => state.itemsControlesSig,

        itemsTipoSuboperaciones: state => state.itemsTipoSuboperaciones,
        itemsCategoriaSuboperaciones: state => state.itemsCategoriaSuboperaciones,
        itemsTipoControlesSig: state => state.itemsTipoControlesSig,
        itemsCategoriaControlesSig: state => state.itemsCategoriaControlesSig,

        listaMateriales: state => state.listaMateriales,
        itemsUMmaterial: state => state.itemsUMmaterial,
        itemsTipoMaterial: state => state.itemsTipoMaterial,
        itemsMoneda: state => state.itemsMoneda,
        itemsUMservicio: state => state.itemsUMservicio,
        listaServicios: state => state.listaServicios,
        listaEmpresas: state => state.listaEmpresas,

        orden_trabajo: state => state.orden_trabajo,
        listaOperaciones: state => state.listaOperaciones,
        listaSubOperaciones: state => state.listaSubOperaciones,
        listaControlesSig: state => state.listaControlesSig,
        listaMaterialesOrden: state => state.listaMaterialesOrden,
        listaServiciosOrden: state => state.listaServiciosOrden,
        editOperacion: state => state.editOperacion,
        editarOperacion: state => state.editarOperacion,
        editarSuboperacion: state => state.editarSuboperacion,
        editarControl: state => state.editarControl,
        editarMaterial: state => state.editarMaterial,
        editarServicio: state => state.editarServicio,
        editMaterial: state => state.editMaterial,
        editServicio: state => state.editServicio,
        ordenObtenida: state => state.ordenObtenida,
        numeroOrden: state => state.numeroOrden,
        resetearOrden: state => state.resetearOrden,
        listaOrdenes: state => state.listaOrdenes,
        listaOrdenesPlanificar: state => state.listaOrdenesPlanificar,
        listaOrdenesEjecutadas: state => state.listaOrdenesEjecutadas,
        listaOrdenesEjecucion: state => state.listaOrdenesEjecucion,
        listaOrdenesProgramar: state => state.listaOrdenesProgramar,
        nuevaOrden: state => state.nuevaOrden,
        listaOrdenesFiltradas: state => state.listaOrdenesFiltradas,
        listaTrazabilidad: state => state.listaTrazabilidad,
        listaModoFallo: state => state.listaModoFallo,
        listaTiposEquipos: state => state.listaTiposEquipos,
        listaAreas: state => state.listaAreas,

    },
    mutations: {
        SET_TABCABECERA(state, tabCabecera) {
            state.tabCabecera = tabCabecera
        },
        SET_TABOPERACIONES(state, tabOperaciones) {
            state.tabOperaciones = tabOperaciones
        },
        SET_TABSUBOPERACIONES(state, tabSuboperaciones) {
            state.tabSuboperaciones = tabSuboperaciones
        },
        SET_TABSIG(state, tabSig) {
            state.tabSig = tabSig
        },
        SET_TABMATERIALES(state, tabMateriales) {
            state.tabMateriales = tabMateriales
        },
        SET_TABSERVICIOS(state, tabServicios) {
            state.tabServicios = tabServicios
        },
        SET_TABTRAZABILIDAD(state, tabTrazabilidad) {
            state.tabTrazabilidad = tabTrazabilidad
        },
        SET_TABSOLICITUDES(state, tabSolicitudes) {
            state.tabSolicitudes = tabSolicitudes
        },
        SET_SELECTTAB(state, selectTab) {
            state.selectTab = selectTab
        },

        SET_ITEMSTIPOORDEN(state, itemsTipoOrden) {
            state.itemsTipoOrden = itemsTipoOrden
        },
        SET_ITEMSPRIORIDADORDEN(state, itemsPrioridadOrden) {
            state.itemsPrioridadOrden = itemsPrioridadOrden
        },
        SET_ITEMSDISCIPLINAORDEN(state, itemsDisciplinaOrden) {
            state.itemsDisciplinaOrden = itemsDisciplinaOrden
        },
        SET_ITEMSESTADOORDEN(state, itemsEstadoOrden) {
            state.itemsEstadoOrden = itemsEstadoOrden
        },
        SET_ITEMSPARADAORDEN(state, itemsParadaOrden) {
            state.itemsParadaOrden = itemsParadaOrden
        },
        SET_ITEMSCONFORMIDADORDEN(state, itemsConformidadOrden) {
            state.itemsConformidadOrden = itemsConformidadOrden
        },


        SET_EQUIPOS(state, listaEquipos) {
            state.listaEquipos = listaEquipos
        },
        SET_UBICACIONEQUIPOS(state, ubicacionEquipos) {
            state.ubicacionEquipos = ubicacionEquipos
        },
        SET_CENTROCOSTOS(state, listaCentroCostos) {
            state.listaCentroCostos = listaCentroCostos
        },
        SET_DEPARTAMENTOS(state, listaDepartamentos) {
            state.listaDepartamentos = listaDepartamentos
        },
        SET_RESPONSABLES(state, listaResponsables) {
            state.listaResponsables = listaResponsables
        },
        SET_RECURSOS(state, listaRecursos) {
            state.listaRecursos = listaRecursos
        },

        SET_ITEMSSUBOPERACIONES(state, itemsSuboperaciones) {
            state.itemsSuboperaciones = itemsSuboperaciones
        },
        SET_ITEMSCONTROLESSIG(state, itemsControlesSig) {
            state.itemsControlesSig = itemsControlesSig
        },

        SET_ITEMSTIPOSUBOPERACIONES(state, itemsTipoSuboperaciones) {
            state.itemsTipoSuboperaciones = itemsTipoSuboperaciones
        },
        SET_ITEMSCATEGORIASUBOPERACIONES(state, itemsCategoriaSuboperaciones) {
            state.itemsCategoriaSuboperaciones = itemsCategoriaSuboperaciones
        },
        SET_ITEMSTIPOCONTROLESSIG(state, itemsTipoControlesSig) {
            state.itemsTipoControlesSig = itemsTipoControlesSig
        },
        SET_ITEMSCATEGORIACONTROLESSIG(state, itemsCategoriaControlesSig) {
            state.itemsCategoriaControlesSig = itemsCategoriaControlesSig
        },

        SET_LISTAMATERIALES(state, listaMateriales) {
            state.listaMateriales = listaMateriales
        },
        SET_ITEMSUMMATERIAL(state, itemsUMmaterial) {
            state.itemsUMmaterial = itemsUMmaterial
        },
        SET_ITEMSTIPOMATERIAL(state, itemsTipoMaterial) {
            state.itemsTipoMaterial = itemsTipoMaterial
        },
        SET_ITEMSMONEDA(state, itemsMoneda) {
            state.itemsMoneda = itemsMoneda
        },
        SET_LISTASERVICIOS(state, listaServicios) {
            state.listaServicios = listaServicios
        },
        SET_LISTAEMPRESAS(state, listaEmpresas) {
            state.listaEmpresas = listaEmpresas
        },
        SET_ITEMSUMSERVICIO(state, itemsUMservicio) {
            state.itemsUMservicio = itemsUMservicio
        },

        SET_ORDENTRABAJO(state, orden_trabajo) {
            state.orden_trabajo = orden_trabajo
        },
        SET_LISTAOPERACIONES(state, listaOperaciones) {
            state.listaOperaciones = listaOperaciones
        },
        SET_LISTASUBOPERACIONES(state, listaSubOperaciones) {
            state.listaSubOperaciones = listaSubOperaciones
        },
        SET_LISTACONTROLESSIG(state, listaControlesSig) {
            state.listaControlesSig = listaControlesSig
        },
        SET_LISTAMATERIALESORDEN(state, listaMaterialesOrden) {
            state.listaMaterialesOrden = listaMaterialesOrden
        },
        SET_LISTASERVICIOSORDEN(state, listaServiciosOrden) {
            state.listaServiciosOrden = listaServiciosOrden
        },
        SET_EDITOPERACION(state, editOperacion) {
            state.editOperacion = editOperacion
        },
        SET_EDITAROPERACION(state, editarOperacion) {
            state.editarOperacion = editarOperacion
        },
        SET_EDITARSUBOPERACION(state, editarSuboperacion) {
            state.editarSuboperacion = editarSuboperacion
        },
        SET_EDITARCONTROL(state, editarControl) {
            state.editarControl = editarControl
        },
        SET_EDITARMATERIAL(state, editarMaterial) {
            state.editarMaterial = editarMaterial
        },
        SET_EDITARSERVICIO(state, editarServicio) {
            state.editarServicio = editarServicio
        },

        SET_EDITMATERIAL(state, editMaterial) {
            state.editMaterial = editMaterial
        },
        SET_EDITSERVICIO(state, editServicio) {
            state.editServicio = editServicio
        },
        SET_ORDENOBTENIDA(state, ordenObtenida) {
            state.ordenObtenida = ordenObtenida
        },
        SET_NUMEROORDEN(state, numeroOrden) {
            state.numeroOrden = numeroOrden
        },
        SET_RESETEARORDEN(state, resetearOrden) {
            state.resetearOrden = resetearOrden
        },
        SET_LISTAORDENES(state, listaOrdenes) {
            state.listaOrdenes = listaOrdenes
        },
        SET_LISTAORDENESPLANIFICAR(state, listaOrdenesPlanificar) {
            state.listaOrdenesPlanificar = listaOrdenesPlanificar
        },
        SET_LISTAORDENESEJECUTADAS(state, listaOrdenesEjecutadas) {
            state.listaOrdenesEjecutadas = listaOrdenesEjecutadas
        },
        SET_LISTAORDENESEJECUCION(state, listaOrdenesEjecucion) {
            state.listaOrdenesEjecucion = listaOrdenesEjecucion
        },
        SET_LISTAORDENESPROGRAMAR(state, listaOrdenesProgramar) {
            state.listaOrdenesProgramar = listaOrdenesProgramar
        },
        SET_NUEVAORDEN(state, nuevaOrden) {
            state.nuevaOrden = nuevaOrden;
        },
        SET_LISTAORDENESFILTRADAS(state, listaOrdenesFiltradas) {
            state.listaOrdenesFiltradas = listaOrdenesFiltradas;
        },
        SET_LISTATRAZABILIDAD(state, listaTrazabilidad) {
            state.listaTrazabilidad = listaTrazabilidad;
        },
        SET_LISTAMODOFALLO(state, listaModoFallo) {
            state.listaModoFallo = listaModoFallo;
        },
        SET_LISTATIPOSEQUIPOS(state, listaTiposEquipos) {
            state.listaTiposEquipos = listaTiposEquipos;
        },
        SET_LISTAAREAS(state, listaAreas){
            state.listaAreas = listaAreas;
        }

    },
    actions: {
        SELECT_TABCABECERA({ commit }, estado) {
            commit('SET_TABCABECERA', estado)
        },
        SELECT_TABOPERACIONES({ commit }, estado) {
            commit('SET_TABOPERACIONES', estado)
        },
        SELECT_TABSUBOPERACIONES({ commit }, estado) {
            commit('SET_TABSUBOPERACIONES', estado)
        },
        SELECT_TABSIG({ commit }, estado) {
            commit('SET_TABSIG', estado)
        },
        SELECT_TABMATERIALES({ commit }, estado) {
            commit('SET_TABMATERIALES', estado)
        },
        SELECT_TABSERVICIOS({ commit }, estado) {
            commit('SET_TABSERVICIOS', estado)
        },
        SELECT_TABTRAZABILIDAD({ commit }, estado) {
            commit('SET_TABTRAZABILIDAD', estado)
        },
        SELECT_TABSOLICITUDES({ commit }, estado) {
            commit('SET_TABSOLICITUDES', estado)
        },
        SELECT_TAB({ commit }, estado) {
            commit('SET_SELECTTAB', estado)
        },

        async GET_ITEMSTIPOORDEN({ getters, commit }) {
            if (getters.itemsTipoOrden.length > 0)
                return;
            axios.get('tipoOT').then(response => {
                const items = response.data;
                commit('SET_ITEMSTIPOORDEN', items)
            });
        },
        async GET_ITEMSPRIORIDADORDEN({ getters, commit }) {
            if (getters.itemsPrioridadOrden.length > 0)
                return;
            await axios.get('prioridadOT').then(response => {
                const items = response.data;
                commit('SET_ITEMSPRIORIDADORDEN', items)
            });
        },
        async GET_ITEMSDISCIPLINAORDEN({ getters, commit }) {
            if (getters.itemsDisciplinaOrden.length > 0)
                return;
            await axios.get('disciplinaOT').then(response => {
                const items = response.data;
                commit('SET_ITEMSDISCIPLINAORDEN', items)
            });
        },
        async GET_ITEMSESTADOORDEN({ getters, commit }) {
            if (getters.itemsEstadoOrden.length > 0)
                return;
            await axios.get('estadosOT').then(response => {
                const items = response.data;
                commit('SET_ITEMSESTADOORDEN', items)
            });
        },
        async GET_ITEMSPARADAORDEN({ commit }) {
            await axios.get('paradasPlanta').then(response => {
                const items = response.data;
                commit('SET_ITEMSPARADAORDEN', items)
            });
        },
        async GET_ITEMSCONFORMIDAD({ commit }) {
            axios.get('conformidad').then(response => {
                const items = response.data;
                commit('SET_ITEMSCONFORMIDADORDEN', items)
            });
        },

        async GET_EQUIPOS({ commit }, equipo) {
            await axios.get('ObjetosTecnicos', {
                params: {
                    codigo: equipo[0],
                    descripcion: equipo[1],
                    ubicacion: equipo[2],
                    medidores: equipo[3],
                    tipo: equipo[4]
                }
            }).then(response => {
                const items = response.data;
                commit('SET_EQUIPOS', items)
            }).catch(error => {
                const items = [];
                commit('SET_EQUIPOS', items)
            });
        },
        async GET_UBICACIONEQUIPOS({ commit }) {
            await axios.get('UbicacionFisica').then(response => {
                const items = response.data;
                commit('SET_UBICACIONEQUIPOS', items)
            }).catch(error => {
                const items = [];
                commit('SET_UBICACIONEQUIPOS', items)
            });
        },
        async GET_LISTATIPOSEQUIPOS({ commit }) {
            await axios.get('obtener_parametro_order_id', {
                params: {
                    tipo: 11,
                    estado: 1,
                }
            }).then(response => {
                const items = response.data;
                commit('SET_LISTATIPOSEQUIPOS', items)
            }).catch(error => {
                console.log(error);
                commit('SET_LISTATIPOSEQUIPOS', [])
            });
        },
        async GET_CENTROCOSTOS({ commit }, cc) {
            await axios.get('CentroCostos', {
                params: {
                    codigo: cc[0],
                    descripcion: cc[1]
                }
            }).then(response => {
                const items = response.data;
                commit('SET_CENTROCOSTOS', items)
            }).catch(error => {
                const items = [];
                commit('SET_CENTROCOSTOS', items)
            });
        },
        async GET_DEPARTAMENTOS({ commit }, departamento) {
            await axios.get('Departamentos', {
                params: {
                    codigo: departamento[0],
                    descripcion: departamento[1]
                }
            }).then(response => {
                const items = response.data;
                commit('SET_DEPARTAMENTOS', items)
            }).catch(error => {
                const items = [];
                commit('SET_DEPARTAMENTOS', items)
            });
        },
        async GET_RESPONSABLES({ commit }, responsable) {
            await axios.get('Empleados', {
                params: {
                    codigo: responsable[0],
                    nombres: responsable[1],
                    idRecurso: responsable[2]
                }
            }).then(response => {
                const items = response.data;
                commit('SET_RESPONSABLES', items)
            }).catch(error => {
                const items = [];
                commit('SET_RESPONSABLES', items)
            });
        },
        async GET_RECURSOS({ commit }) {
            axios.get('recursos').then(response => {
                const items = response.data;
                commit('SET_RECURSOS', items)
            }).catch(error => {
                const items = [];
                commit('SET_RECURSOS', items)
            });
        },
        async GET_ITEMSSUBOPERACIONES({ commit }, filtro) {
            await axios.get('Suboperaciones', {
                params: {
                    codigo: filtro[0],
                    nombres: filtro[1],
                }
            }).then(response => {
                const items = response.data;
                commit('SET_ITEMSSUBOPERACIONES', items)
            }).catch(error => {
                const items = [];
                commit('SET_ITEMSSUBOPERACIONES', items)
            });
        },
        async GET_ITEMSCONTROLESSIG({ commit }, filtro) {
            await axios.get('controlSIG', {
                params: {
                    codigo: filtro[0],
                    nombres: filtro[1],
                }
            }).then(response => {
                const items = response.data;
                commit('SET_ITEMSCONTROLESSIG', items)
            }).catch(error => {
                const items = [];
                commit('SET_ITEMSCONTROLESSIG', items)
            });
        },
        async GET_ITEMSTIPOSUBOPERACIONES({ commit }) {
            await axios.get('TipoValorSuboperaciones').then(response => {
                const items = response.data;
                commit('SET_ITEMSTIPOSUBOPERACIONES', items)
            });
        },
        async GET_ITEMSCATEGORIASUBOPERACIONES({ getters, commit }) {
            if (getters.itemsCategoriaSuboperaciones.length > 0)
                return;
            await axios.get('CategoriaSuboperaciones').then(response => {
                const items = response.data;
                commit('SET_ITEMSCATEGORIASUBOPERACIONES', items)
            });
        },
        async GET_ITEMSTIPOCONTROLESSIG({ commit }) {
            await axios.get('TipoValorSuboperaciones').then(response => {
                const items = response.data;
                commit('SET_ITEMSTIPOCONTROLESSIG', items)
            });
        },
        async GET_ITEMSCATEGORIACONTROLESSIG({ getters, commit }) {
            if (getters.itemsCategoriaControlesSig.length > 0)
                return;
            await axios.get('CategoriaSig').then(response => {
                const items = response.data;
                commit('SET_ITEMSCATEGORIACONTROLESSIG', items)
            });
        },

        async GET_LISTAMATERIAL({ commit }, material) {
            await axios.get('materiales', {
                params: {
                    codigo: material[0],
                    nombres: material[1],
                }
            }).then(response => {
                const items = response.data;
                commit('SET_LISTAMATERIALES', items)
            }).catch(error => {
                const items = [];
                commit('SET_LISTAMATERIALES', items)
            });
        },
        async GET_ITEMSUMMATERIAL({ commit }) {
            await axios.get('unidadesMedidaMaterial').then(response => {
                const items = response.data;
                commit('SET_ITEMSUMMATERIAL', items)
            });
        },
        async GET_ITEMSTIPOMATERIAL({ commit }) {
            await axios.get('tiposMaterial').then(response => {
                const items = response.data;
                commit('SET_ITEMSTIPOMATERIAL', items)
            });
        },
        async GET_ITEMSMONEDA({ commit }) {
            await axios.get('Monedas').then(response => {
                const items = response.data;
                commit('SET_ITEMSMONEDA', items)
            });
        },
        async GET_LISTASERVICIOS({ commit }, servicio) {
            await axios.get('servicios', {
                params: {
                    codigo: servicio[0],
                    nombres: servicio[1],
                }
            }).then(response => {
                const items = response.data;
                commit('SET_LISTASERVICIOS', items)
            }).catch(error => {
                const items = [];
                commit('SET_LISTASERVICIOS', items)
            });
        },
        async GET_LISTAEMPRESAS({ commit }, empresa) {
            await axios.get('Empresas', {
                params: {
                    codigo: empresa[0],
                    nombres: empresa[1],
                }
            }).then(response => {
                const items = response.data;
                commit('SET_LISTAEMPRESAS', items)
            }).catch(error => {
                const items = [];
                commit('SET_LISTAEMPRESAS', items)
            });
        },
        async GET_ITEMSUMSERVICIO({ commit }) {
            await axios.get('unidadesMedidaServicio').then(response => {
                const items = response.data;
                commit('SET_ITEMSUMSERVICIO', items)
            });
        },

        SETEAR_ORDENTRABAJO({ commit }, orden_trabajo) {
            commit('SET_ORDENTRABAJO', orden_trabajo)
        },
        SETEAR_LISTAOPERACIONES({ commit }, listaOperaciones) {
            commit('SET_LISTAOPERACIONES', listaOperaciones)
        },
        SETEAR_LISTASUBOPERACIONES({ commit }, listaSubOperaciones) {
            commit('SET_LISTASUBOPERACIONES', listaSubOperaciones)
        },
        SETEAR_LISTACONTROLESSIG({ commit }, listaControlesSig) {
            commit('SET_LISTACONTROLESSIG', listaControlesSig)
        },
        SETEAR_LISTAMATERIALESORDEN({ commit }, listaMaterialesOrden) {
            commit('SET_LISTAMATERIALESORDEN', listaMaterialesOrden)
        },
        SETEAR_LISTASERVICIOSORDEN({ commit }, listaServiciosOrden) {
            commit('SET_LISTASERVICIOSORDEN', listaServiciosOrden)
        },

        ADD_NEWOPERACION({ commit }, orden) {
            const ordenTrabajoOperacion = {
                idOrdenTrabajo: orden.id,
                descripcion: orden.descripcion,
                idDepartamento: { id: 1 },
                id014EstadoOrdenTrabajoOperacion: 1,
                numero: 1,
                fechaInicioProgramada: orden.fechaInicioProgramada,
                horaInicioProgramada: orden.horaInicioProgramada,
                duracionProgramada: orden.duracionProgramada,
                fechaFinProgramada: orden.fechaFinProgramada,
                horaFinProgramada: orden.horaFinProgramada,
                fechaInicioReal: null,
                horaInicioReal: null,
                duracionReal: null,
                fechaFinReal: null,
                horaFinReal: null,
            };
            const listOp = [];
            listOp.push(ordenTrabajoOperacion);
            var idOper = 0;
            axios.post('agregarOrdenTrabajoOperacion', listOp)
                .then(response => {
                    idOper = response.data.idsOperacion[0];
                    const listOrdenTrabajoRecurso = [];
                    const OrdenTrabajoOperacionRecurso = {
                        id: null,
                        idOrdenTrabajoOperacion: { id: idOper },
                        idRecurso: { id: 1 },
                        cantidadProgramado: 1,
                        cantidadReal: 0,
                        horasHombreProgramado: 0,
                        horasHombreReal: 0,
                        id001Estado: 1,
                    }
                    listOrdenTrabajoRecurso.push(OrdenTrabajoOperacionRecurso);

                    axios.post('guardarOrdenTrabajoRecurso', listOrdenTrabajoRecurso)
                        .then(response => {
                            var nro = 1;
                            const operacion = [{
                                id: response.data.recursos[0].id,
                                idOp: idOper,
                                des: orden.descripcion,
                                nro: nro,
                                sec: 1,
                                dur: orden.duracionProgramada,
                                recurso: null,
                                can: 1,
                                instaNomb: false,
                                dep: orden.idDepartamento,
                                ini: orden.fechaInicioProgramada,
                                hini: orden.horaInicioProgramada,
                                fin: orden.fechaFinProgramada + ' ' + orden.horaFinProgramada,
                                idOpRecurso: response.data.idsRecursos[0],
                                rec: [],
                                recNomb: '',
                                adjuntos: [],
                                flagRecurso: false,
                                hh: 0,
                            }];
                            commit('SET_LISTAOPERACIONES', operacion);
                            commit('SET_EDITOPERACION', true);

                        })
                })
        },
        SETEAR_EDITOPERACION({ commit }, item) {
            commit('SET_EDITOPERACION', item);
        },
        SETEAR_EDITAROPERACION({ commit }, item) {
            commit('SET_EDITAROPERACION', item);
        },
        SETEAR_EDITARSUBOPERACION({ commit }, item) {
            commit('SET_EDITARSUBOPERACION', item);
        },
        SETEAR_EDITARCONTROL({ commit }, item) {
            commit('SET_EDITARCONTROL', item);
        },
        SETEAR_EDITARMATERIAL({ commit }, item) {
            commit('SET_EDITARMATERIAL', item);
        },
        SETEAR_EDITARSERVICIO({ commit }, item) {
            commit('SET_EDITARSERVICIO', item);
        },
        SETEAR_EDITMATERIAL({ commit }, item) {
            commit('SET_EDITMATERIAL', item);
        },
        SETEAR_EDITSERVICIO({ commit }, item) {
            commit('SET_EDITSERVICIO', item);
        },
        SETEAR_ORDENOBTENIDA({ commit }, item) {
            commit('SET_ORDENOBTENIDA', item);
        },
        SETEAR_NUMEROORDEN({ commit }, item) {
            commit('SET_NUMEROORDEN', item);
        },
        async SETEAR_NUMEROORDENCONSULTA({ commit }) {
            await axios.get('NumOrdenTrabajo').then(response => {
                const items = String(response.data).padStart(8, '0');
                commit('SET_NUMEROORDEN', items);
            });
        },
        RESETEAR_ORDEN({ commit }, item) {
            commit('SET_RESETEARORDEN', item);
        },
        async GET_LISTAORDENES({ commit }) {
            await axios.get('listaOrdenes').then((response) => {
                const ordenes = response.data;
                commit('SET_LISTAORDENES', ordenes);
                const ordenesPlanificar = ordenes.filter(card => card.idEstado === 52 || card.idEstado === 51);
                const ordenesProgamar = ordenes.filter(card => card.idEstado === 53);
                const ordenesEjecutadas = ordenes.filter(card => card.idEstado === 56);
                const ordenesEjecucion = ordenes.filter(card => card.idEstado === 54 || card.idEstado === 55 || card.idEstado === 59);
                commit('SET_LISTAORDENESPLANIFICAR', ordenesPlanificar);
                commit('SET_LISTAORDENESEJECUTADAS', ordenesEjecutadas);
                commit('SET_LISTAORDENESEJECUCION', ordenesEjecucion);
                commit('SET_LISTAORDENESPROGRAMAR', ordenesProgamar);
            })
        },
        SETEAR_LISTAORDENESEJECUTADAS({ commit }, lista) {
            commit('SET_LISTAORDENESEJECUTADAS', lista);
        },
        SETEAR_LISTAORDENESPORPLANIFICAR({ commit }, lista) {
            commit('SET_LISTAORDENESPLANIFICAR', lista);
        },
        SETEAR_LISTAORDENESEJECUCION({ commit }, lista) {
            commit('SET_LISTAORDENESEJECUCION', lista);
        },
        SETEAR_LISTAORDENESPROGRAMAR({ commit }, lista) {
            commit('SET_LISTAORDENESPROGRAMAR', lista);
        },
        SETEAR_NUEVAORDEN({ commit }, item) {
            commit('SET_NUEVAORDEN', item)
        },
        async GET_LISTAORDENESFILTRADAS({ commit }, filtros) {

            await axios.get('ordenes_trabajo_filtrados', {
                params: {
                    idEstado: filtros[0],
                    idPrioridad: filtros[1],
                    fechaInicio: filtros[2],
                    fechaFin: filtros[3],
                    idDepartamento: filtros[4],
                    idCc: filtros[5],
                    idResponsable: filtros[6],
                    paradaPlanta: filtros[7],
                    paradaEquipo: filtros[8],
                    numero: filtros[9],
                    descripcion: filtros[10],
                    equipo: filtros[11],
                    disciplina: filtros[12],
                    idRevertido: filtros[13]
                }
            }).then(response => {
                let solicitudes = response.data;
                commit('SET_LISTAORDENESFILTRADAS', solicitudes)
            });
        },
        SETEAR_TRAZABILIDAD({ commit }, trazabilidad) {
            commit('SET_LISTATRAZABILIDAD', trazabilidad);
        },
        async GET_LISTAMODOFALLO({ commit }) {
            await axios.get('lista_modo_falla').then(response => {
                const items = response.data;
                commit('SET_LISTAMODOFALLO', items)
            }).catch(error => {
                const items = [];
                commit('SET_LISTAMODOFALLO', items)
            });
        },
        async GET_LISTAAREAS({ commit }) {
            await axios.get('listar_areas').then(response => {
                const items = response.data;
                commit('SET_LISTAAREAS', items)
            });
        },

    },
    modules: {
    }
}

